body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
}

body {
  /* padding-top: 100px;
  padding-bottom: 80px; */
  background-color: #222;
  max-width: 1080px;
  min-width: 640px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  padding-bottom: 15;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "Bangers Regular";
  src: url("../fonts/Bangers/Bangers-Regular.ttf");
}

@font-face {
  font-family: "Futura Medium";
  src: url("../fonts/1119900/c18fc865-7a4e-45d9-bbb0-4e4553cc4136.ttf");
}

@font-face {
  font-family: "Futura ExtraBlack";
  src: url("../fonts/1119903/b97c5578-ea02-4f6e-a90f-1696f2815211.ttf");
}

@font-face {
  font-family: "Futura Medium Italic";
  src: url("../fonts/FuturaMediumItalic.ttf");
}
